<template>
  <admin-base ref="base">
    <div slot="title">
      Empresas
    </div>
    <div class="card">
      <div class="card-body">
        <advanced-table
          :columns="columns"
          :values="companies"
          :filtered-size="filteredSize"
          :on-start-load-data="onStartLoadData"
          :on-loaded-data="onLoadedData"
          :on-select-item="onSelectData"
          :paginated="true"
          :show-filter="true"
          :filter-case-sensitive="false"
          empty-message="Nenhuma empresa encontrada"
        />
        <div class="col-sm-6 col-md-6 col-lg-6">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="$router.push({ name: 'admin-companies-new' })">
            Create
          </b-button>
        </div>
      </div>
    </div>
  </admin-base>
</template>

<script>
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

import * as bootstrapVue from 'bootstrap-vue'

import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import AdminBase from '@/views/admin/AdminBase.vue'

const AdvancedTable = () => import('@core/components/AdvancedTableComponent.vue')

export default {
  components: {
    AdminBase,
    AdvancedTable,
    BButton: bootstrapVue.BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const companies = ref([])

    return {
     companies,
    }
  },
  data() {
    return {
      columns: [
        {
            title: 'ID',
            name: 'id',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
            filtered: false,
        },
        {
            title: 'Ativo',
            name: 'enabled',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
        },
        {
            title: 'Nome',
            name: 'name',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
      ],
      sortColunms: [],
      filteredSize: 0,
      onStartLoadData() {
      },
      onLoadedData(data) {
          const self = this.parent
          self.detailObj = data.Data

          self.registraNavecaoComContexto()
          self.filteredSize = data.total

          self.loadCheckboxConteudo()

          self.showResults = true
          self.verifyDate()
      },
      onSelectData(event, data) {
        this.$router.push({ name: 'admin-companies-edit', params: { id: data.id } })
      },
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      const self = this
      this.$refs.base.showLoading()
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/companies/list?page=1`, useJwt.getHeader())
      .then(response => {
        self.companies = response.data.companies.data ?? []
      })
      .catch(error => {
        this.$refs.base.showToastError(error.response.data.message)
        this.errored = true
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
  },
}
</script>
